import { Link, useParams } from "react-router-dom";
import productData from "./common/Helper";

<productData />;
const ProductDetailsHeader = () => {
  const { id } = useParams();
  const product = productData.find((p) => p.id === parseInt(id));
  return (
    <>
      <div
        className="bg-contactbg bg-cover bg-no-repeat md:bg-center bg-right h-[300px] flex justify-center items-center"
        data-aos="fade-down"
      >
        <h2
          className="font-bold text-white sm:text-5xl text-4xl"
          data-aos="zoom-in"
        >
          Product Details
        </h2>
      </div>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4">
        <div className="flex flex-wrap justify-between items-center mb-12">
          <div className="lg:w-1/2 w-full">
            <div className="overflow-hidden w-full h-full rounded-lg">
              <img
                src={product.image}
                alt={product.image}
                className="w-full bg-slate-200 group-hover:scale-105 transition-transform duration-300 aspect-square"
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full">
            <div className="md:ps-6 flex flex-col gap-2">
              <h4 className="text-[#002347] text-2xl leading-base font-extrabold">
                {product.description}
              </h4>
              <p className="text-[#002347] font-bold text-xl">{product.name}</p>
              <p className="text-[#002347] text-base">
                <span className="font-bold">INTRODUCTION: </span>
                {product.intro}
              </p>
              <p className="text-[#002347] text-base mt-2">
                <span className="font-bold">{product.heading}</span>
                {product.effect}
              </p>
              <div>
                <Link
                  to="/contactus"
                  className="btn inline-block font-medium text-nowrap text-base px-6 py-1 text-[#002347] hover:text-[#efc368] bg-[#efc368] hover:bg-[#002347] shadow-[2px_3px_6px_#002347] rounded cursor-pointer transition duration-500"
                >
                  More Inquiry
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsHeader;
