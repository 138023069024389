import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutUs from "../pages/AboutUs";
import OurProducts from "../pages/OurProducts";
import ContactUs from "../pages/ContactUs";
import CatalogPage from "../pages/CatalogPages";
import ProductDetails from "../pages/ProductDetails";

const MainRoute = () => {
  // =======================aos============================
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="aboutus" element={<AboutUs />} />
      <Route path="ourproducts" element={<OurProducts />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route path="ourcatalog" element={<CatalogPage />} />
      <Route path="productdetails/:id" element={<ProductDetails />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default MainRoute;
