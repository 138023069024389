import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import WebLogo from "../../assets/images/webp/WebLogo.webp";
import CommonBtn from "./CommonBtn";

const NavBar = () => {
  const [show, setShow] = useState(true);
  const [scrollProgress, setScrollProgress] = useState(0);

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = document.body.scrollHeight - window.innerHeight;
      const scrolled = (scrollTop / windowHeight) * 100;
      setScrollProgress(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!show) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [show]);

  return (
    <>
      <div className="shadow-xl sticky top-0 z-20 bg-white">
        <nav className="flex justify-between items-center xl:max-w-[1320px] mx-auto md:px-6 px-4 py-2">
          <a href="/" className="text-base flex items-center">
            <img src={WebLogo} alt="WebLogo" className="w-full max-h-14" />
          </a>
          <ul
            className={`${
              show ? "left-[-100%]" : "left-0 z-10"
            } gap-[51px] flex transition-all duration-300 ease-linear md:bg-white bg-white max-md:w-full max-md:fixed max-md:top-0 max-md:min-h-screen max-md:flex max-md:flex-col max-md:items-center max-md:justify-center bottom-0`}
          >
            <Link
              to="/"
              className={`${
                isActive("/")
                  ? "!text-[#002347] font-bold after:right-[11%]"
                  : "!text-[#002347] after:right-[89%]"
              } text-base flex items-center hover:font-bold transition-all ease-in-out duration-300 after:absolute relative after:transition-all after:duration-300 after:ease-linear after:h-[2px] after:rounded-[10px] after:bg-[#002347] after:left-[11%] hover:after:right-[11%] after:bottom-0`}
            >
              Home
            </Link>
            <Link
              to="/aboutus"
              className={`${
                isActive("/aboutus")
                  ? "!text-[#002347] font-bold after:right-[11%]"
                  : "!text-[#002347] after:right-[89%]"
              } text-base flex items-center hover:font-bold transition-all ease-in-out duration-300 after:absolute relative after:transition-all after:duration-300 after:ease-linear after:h-[2px] after:rounded-[10px] after:bg-[#002347] after:left-[11%] hover:after:right-[11%] after:bottom-0`}
            >
              About Us
            </Link>
            <Link
              to="/ourproducts"
              className={`${
                isActive("/ourproducts")
                  ? "!text-[#002347] font-bold after:right-[11%]"
                  : "!text-[#002347] after:right-[89%]"
              } text-base flex items-center hover:font-bold transition-all ease-in-out duration-300 after:absolute relative after:transition-all after:duration-300 after:ease-linear after:h-[2px] after:rounded-[10px] after:bg-[#002347] after:left-[11%] hover:after:right-[11%] after:bottom-0`}
            >
              Our Products
            </Link>
            <Link
              to="/contactus"
              className={`${
                isActive("/contactus")
                  ? "text-[#002347] font-bold after:right-[11%]"
                  : "!text-[#002347] after:right-[89%]"
              } text-base flex items-center hover:font-bold transition-all ease-in-out duration-300 after:absolute relative after:transition-all after:duration-300 after:ease-linear after:h-[2px] after:rounded-[10px] after:bg-[#002347] after:left-[11%] hover:after:right-[11%] after:bottom-0`}
            >
              Contact Us
            </Link>
            <Link to="/ourcatalog">
              <CommonBtn btnname="Our Catalog" />
            </Link>
          </ul>
          <div
            onClick={() => setShow(!show)}
            className="w-[35px] h-[25px] relative z-50 flex flex-col justify-between md:hidden cursor-pointer"
          >
            <span
              className={`${
                show
                  ? "bg-[#002347] w-full h-[4px] transition-all duration-300 ease-linear rounded-3xl"
                  : "bg-[#002347] w-full h-[4px] transition-all duration-300 ease-linear rounded-3xl rotate-[48deg] translate-y-[23px]"
              }`}
            ></span>
            <span
              className={`${
                show
                  ? "bg-[#002347] w-full h-[4px] transition-all duration-300 ease-linear rounded-3xl"
                  : "opacity-0"
              }`}
            ></span>
            <span
              className={`${
                show
                  ? "bg-[#002347] w-full h-[4px] transition-all duration-300 ease-linear rounded-3xl"
                  : "bg-[#002347] w-full h-[4px] transition-all duration-300 ease-linear rounded-3xl rotate-[-50deg] translate-y-[50%]"
              }`}
            ></span>
          </div>
          <div
            className="h-[4px] bg-[#efc368] fixed top-[71.98px] left-0"
            style={{ width: `${scrollProgress}%` }}
          ></div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
