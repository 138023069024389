import React from "react";

const CatalogHeader = () => {
  return (
    <>
      <div
        className="bg-contactbg bg-cover bg-no-repeat bg-center h-[300px] flex justify-center items-center"
        data-aos="fade-down"
      >
        <h2
          className="font-bold text-white sm:text-5xl text-4xl "
          data-aos="zoom-in"
        >
          Our Catalog
        </h2>
      </div>
    </>
  );
};

export default CatalogHeader;
