import React, { useState } from "react";
import Slide1 from "../../src/assets/images/webp/medicine1.webp";
import Slide2 from "../../src/assets/images/webp/medicine2.webp";
import Slide3 from "../../src/assets/images/webp/medicine3.webp";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "./Icons";

const PopularMedicines = () => {
  const slider = React.useRef(null);
  const [prevClicked, setPrevClicked] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);

  const medicines = [
    {
      id: 1,
      name: "Levosalbutamol and Ipratropium Bromide Respirator Solution",
      brand: "DuoFill Respules",
      image: Slide2,
    },
    {
      id: 2,
      name: "Vancomycin Hydrochloride for Injection U.S.P",
      brand: "Gvanco-1000",
      image: Slide1,
    },

    {
      id: 3,
      name: "Levosalbutamol and Ipratropium Bromide Respirator Solution",
      brand: "DuoFill Respules",
      image: Slide3,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 400,
    autoplaySpeed: 1000,
    autoplay: true,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.01,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    setPrevClicked(true);
    slider?.current?.slickPrev();

    setTimeout(() => {
      setPrevClicked(false);
    }, 200);
  };

  const handleNextClick = () => {
    setNextClicked(true);
    slider?.current?.slickNext();

    setTimeout(() => {
      setNextClicked(false);
    }, 200);
  };

  return (
    <>
      <div className="overflow-hidden relative z-10 bg-slate-100 lg:mt-20 mt-12">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-14 py-10 relative z-10">
          <div className="flex flex-wrap justify-between items-center xl:gap-0 gap-10 h-full">
            <div className="xl:w-4/12 w-full relative z-10">
              <h2
                className="text-[#efc368] text-xl font-normal flex items-center"
                data-aos="zoom-in"
              >
                <span className="max-w-28 w-full h-[1px] bg-[#002347] mr-2"></span>
                Our Popular Medicines
              </h2>
              <p
                className="text-3xl text-[#002347] font-bold my-5"
                data-aos="zoom-in"
              >
                Shop Medicine for Your Wellness.
              </p>
              <p
                className="text-lg text-[#002347] font-normal lg:pr-10"
                data-aos="zoom-in"
              >
                Explore a range of medicine products to support your health.
                Prioritize your well-being today!
              </p>
              <div className="flex gap-3 mt-5" data-aos="zoom-in">
                <button
                  className={`bg-white flex justify-center items-center cursor-pointer w-10 h-10 rounded hover:bg-[#efc368] transition-all duration-300 ease-linear ${
                    prevClicked ? "bg-[#efc368] scale-95" : ""
                  }`}
                  onClick={handlePrevClick}
                >
                  <PrevArrow />
                </button>
                <button
                  className={`bg-white flex justify-center items-center cursor-pointer w-10 h-10 rounded hover:bg-[#efc368] transition-all duration-300 ease-linear ${
                    nextClicked ? "bg-[#efc368] scale-95" : ""
                  }`}
                  onClick={handleNextClick}
                >
                  <NextArrow />
                </button>
              </div>
            </div>
            <div className="xl:w-7/12 w-full" data-aos="fade-left">
              <Slider {...settings} ref={slider} className="buy_slider">
                {medicines.map((medicine) => (
                  <div className="px-2 flex flex-col h-full" key={medicine.id}>
                    <div className="bg-white p-3 flex gap-3 flex-col group cursor-pointer h-full">
                      <div className="overflow-hidden">
                        <img
                          src={medicine.image}
                          alt={medicine.name}
                          height={200}
                          className="w-full bg-slate-200 rounded-sm group-hover:scale-105 transition-transform duration-300"
                        />
                      </div>
                      <h4 className="text-[#002347] text-md leading-normal font-medium">
                        {medicine.name}
                      </h4>
                      <p className="text-[#002347] text-normal text-base">
                        {medicine.brand}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularMedicines;
