import { Link } from "react-router-dom";

const CommonBtn = ({ btnname, onClick, path }) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  // If path is provided, use Link for routing, else render a button
  return path ? (
    <Link
      to={path}
      onClick={onClickHandler}
      className="btn text-nowrap px-5 py-2.5 text-[#003366] hover:text-[#efc368] bg-[#efc368] hover:bg-[#003366] shadow-[2px_3px_6px_#003366] font-bold rounded cursor-pointer transition duration-500"
    >
      {btnname}
    </Link>
  ) : (
    <button
      onClick={onClickHandler}
      className="btn text-nowrap px-5 py-2.5 text-[#003366] hover:text-[#efc368] bg-[#efc368] hover:bg-[#003366] shadow-[2px_3px_6px_#003366] font-bold rounded cursor-pointer transition duration-500"
    >
      {btnname}
    </button>
  );
};

export default CommonBtn;
