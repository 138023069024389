import React, { useState, useEffect } from "react";
import MedicineIcon from "../../src/assets/images/svg/medicine_icon.svg";
import Project from "../../src/assets/images/svg/project.svg";
import CoffeeCup from "../../src/assets/images/svg/coffee_cup.svg";
import TotalCapacity from "../../src/assets/images/svg/TotalCapacity.svg";

const Details = () => {
  const detailsData = [
    {
      id: 1,
      icon: Project,
      count: 12,
      label: "Projects Completed",
    },
    {
      id: 2,
      icon: CoffeeCup,
      count: 359,
      label: "Cups of coffee",
    },
    {
      id: 3,
      icon: MedicineIcon,
      count: 12,
      label: "Medicines",
    },
    {
      id: 4,
      icon: TotalCapacity,
      count: 24996,
      label: "Total Capacity",
    },
  ];

  const [counters, setCounters] = useState(
    detailsData.map((item) => 0) 
  );

  
  useEffect(() => {
    const interval = setInterval(() => {
      setCounters((prevCounters) =>
        prevCounters.map((count, index) => {
          const target = detailsData[index].count;
          if (count < target) {
            const increment = Math.ceil(target / 100); 
            return count + increment > target ? target : count + increment;
          }
          return count;
        })
      );
    }, 50);

    return () => clearInterval(interval); 
  }, []);

  return (
    <>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 sm:mt-[-70px] mt-12 relative">
        <div className="bg-[#002347] px-10 pt-8 rounded-xl" data-aos="fade-down">
          <div className="flex flex-wrap items-center justify-center">
            {detailsData.map((detail, index) => (
              <div
                key={detail.id}
                className={`w-full xs:w-6/12 md:w-4/12 lg:w-3/12 mb-8 cursor-pointer ${
                  index === 1 || index === 2
                    ? "lg:border-r-2 border-r-white md:border-l-2 border-l-white"
                    : ""
                }`}
              >
                <div className="text-center flex flex-col gap-1 group">
                  <img
                    src={detail.icon}
                    alt={detail.label}
                    width={50}
                    height={50}
                    className="mx-auto group-hover:scale-95 transition-all duration-300 ease-linear"
                  />
                  <p className="font-semibold text-4xl text-white">
                    {counters[index]}
                  </p>
                  <p className="font-medium text-base text-white">
                    {detail.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
