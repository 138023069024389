import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

const GetInTouch = () => {
  const [formStatus, setFormStatus] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const apiUrl = "https://reqres.in/api/users";

    axios
      .post(apiUrl, values)
      .then((response) => {
        console.log("Success!", response.data);
        setFormStatus("Form submitted successfully!");
        resetForm();
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Form submission failed", error);
        setFormStatus("Error submitting form. Please try again.");
        setSubmitting(false);
      });
  };

  const handleFocus = () => {
    setFormStatus("");
  };

  return (
    <>
      <div className="pt-5 bg-custom_gradient mb-[-1px]">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4">
          <div
            className="bg-slate-100 bg-center bg-full rounded-[20px] md:py-20 py-12 px-3"
            data-aos="fade-down"
          >
            <h2 className="text-center ff_outfit font-light xl:text-[48px] sm:text-[40px] text-[32px] xl:leading-[58px] sm:leading-[45px] leading-[35px] text-[#002347] mb-2">
              Get Update From Anywhere
            </h2>
            <p className="font-normal text-[14px] sm:text-[16px] text-[#002347] leading-[150%] text-center md:mb-9 mb-5">
              Share your email with us, and we will keep you updated with the
              latest updates.
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="max-w-[448px] mx-auto border border-solid border-[#002347] rounded-md bg-transparent md:ps-6 md:pe-2.5 sm:px-2 sm:py-2 p-1 flex">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="font-semibold text-[16px] leading-[24px] placeholder:text-[#002347] bg-transparent text-black outline-none w-full"
                    onFocus={handleFocus} // Clear status message on focus
                  />
                  <button
                    type="submit"
                    className="font-semibold text-[16px] leading-[24px] bg-[#002347] text-white transition-all duration-300 ease-linear px-3 sm:py-[13px] py-3 border-0 text-nowrap rounded hover:shadow-[2px_3px_6px_#003366] hover:scale-95"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Sending..." : "Send Mail"}
                  </button>
                </Form>
              )}
            </Formik>

            {formStatus && (
              <div
                className={`mt-4 text-center font-semibold ${
                  formStatus.includes("successfully")
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {formStatus}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouch;
