import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../common/NavBar";
import AboutHeader from "../AboutHeader";
import OurVision from "../OurVision";
import HowWeWork from "../HowWeWork";
import Footer from "../common/Footer";
import AboutDesign from "../AboutDesign";

const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <div className="overflow-hidden">
        <AboutHeader />
        <OurVision />
        <HowWeWork />
        <AboutDesign />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
