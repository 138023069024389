import React, { useState } from "react";
import CommonBtn from "./common/CommonBtn";
import Slide1 from "../../src/assets/images/webp/medicine1.webp";
import Slide2 from "../../src/assets/images/webp/medicine2.webp";
import Slide3 from "../../src/assets/images/webp/medicine3.webp";
import Slide4 from "../../src/assets/images/webp/medicine4.webp";
import Slide5 from "../../src/assets/images/webp/gcaspo_50.webp";
import Slide6 from "../../src/assets/images/webp/medicine6.webp";
import Slide7 from "../../src/assets/images/webp/medicine7.webp";
import Slide8 from "../../src/assets/images/webp/medicine8.webp";

const AvialableProducts = () => {
  const [visibleProducts, setVisibleProducts] = useState(3);

  const medicines = [
    {
      id: 1,
      name: "Vancomycin Hydrochloride for Injection U.S.P",
      brand: "Gvanco-1000",
      image: Slide1,
      tilte1: "Category : Injection",
      tilte2: "Availibility : In Stock",
      content:
        "Vancomycin injection is used alone or in combination with other medications to treat certain serious infections.",
    },
    {
      id: 2,
      name: "Rifaximin Tablets 400mg",
      brand: "Grifax- 400",
      tilte1: "Category : Tablets",
      tilte2: "Availibility : In Stock",
      image: Slide2,
      content:
        "Rifaximin is used to treat traveler's diarrhea caused by Escherichia coli. It is also used to prevent hepatic encephalopathy.",
    },
    {
      id: 3,
      name: "Levosalbutamol and Ipratropium Bromide Respirator Solution",
      brand: "DuoFill Respules",
      tilte1: "Category : Respules",
      tilte2: "Availibility : In Stock",
      image: Slide3,
      content:
        "Levosalbutamol + Ipratropium is a combination of medicines to help ease breathing issues.",
    },
    {
      id: 4,
      name: "Multivitamin, Multimineral with Grape Seed Extract tablets",
      brand: "C-Zinc",
      tilte1: "Category  : Tablets",
      tilte2: "Availibility : In Stock",
      image: Slide4,
      content:
        "Multivitamin, Multimineral with Grape Seed Extract promotes immunity and enhances energy.",
    },
    {
      id: 5,
      name: "Caspofungin Acetate for Injection 50 mg",
      brand: "Gcaspo-50",
      image: Slide5,
      tilte1: "Category : Injection",
      tilte2: "Availibility : In Stock",
      content:
        "Caspofungin injection is used to treat serious fungal infections that could not be treated successfully with other medications.",
    },
    {
      id: 6,
      name: "Pirfenidone Tablets IP 200mg",
      brand: "Pirfedon-200",
      image: Slide6,
      tilte1: "Category : Tablets",
      tilte2: "Availibility : In Stock",
      content:
        "Pirfenidone is used to treat idiopathic pulmonary fibrosis (IPF), a lung disease causing scarring and breathing issues.",
    },
    {
      id: 7,
      name: "Vancomycin Hydrochloride for Injection U.S.P",
      brand: "Gvanco-500",
      image: Slide7,
      tilte1: "Category : Injection",
      tilte2: "Availibility : In Stock",
      content:
        "Vancomycin injection is used to treat certain serious infections such as endocarditis and infections of the lungs and blood.",
    },
    {
      id: 8,
      name: "Telmisartan Tablets IP",
      brand: "T-Glee 40",
      image: Slide8,
      tilte1: "Category : Tablets",
      tilte2: "Availibility : In Stock",
      content:
        "Telmisartan lowers blood pressure and reduces heart attack or stroke risk by relaxing blood vessels for better blood flow.",
    },
  ];

  const handleShowMore = () => {
    setVisibleProducts(medicines.length);
  };

  return (
    <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
      <div className="flex flex-wrap justify-between items-center gap-5 mb-10">
        <div>
          <h3
            className="text-[#efc368] text-xl font-normal flex items-center"
            data-aos="zoom-in"
          >
            <span className="max-w-28 w-full h-[1px] bg-[#002347] mr-2"></span>
            Our Catalog
          </h3>
          <p
            className="text-3xl text-[#002347] font-bold mt-3"
            data-aos="zoom-in"
          >
            Available Products
          </p>
        </div>
        {visibleProducts < medicines.length && (
          <div data-aos="zoom-in">
            <CommonBtn
              btnname="VIEW ALL PRODUCTS"
              onClick={handleShowMore}
              click={true}
            />
          </div>
        )}
      </div>

      {medicines.slice(0, visibleProducts).map((medicine, index) => (
        <div
          data-aos="fade-down"
          className={`flex flex-wrap justify-between items-center bg-white group rounded-lg shadow-card_shadow md:p-3 p-5 cursor-pointer md:gap-0 gap-4 ${
            index === visibleProducts - 1 ? "" : "mb-10"
          }`}
          key={medicine.id}
        >
          <div className="md:w-3/12 w-full">
            <div className="overflow-hidden w-full h-full rounded-lg">
              <img
                src={medicine.image}
                alt={medicine.name}
                className="w-full bg-slate-200 group-hover:scale-105 transition-transform duration-300 aspect-auto"
              />
            </div>
          </div>
          <div className="md:w-9/12 w-full">
            <div className="md:ps-6 flex flex-col gap-2">
              <h4 className="text-[#002347] md:text-2xl text-xl leading-base font-medium">
                {medicine.name}
              </h4>
              <p className="text-[#002347] font-extrabold text-lg">
                {medicine.brand}
              </p>
              <p className="text-[#002347] text-base">{medicine.tilte1}</p>
              <p className="text-[#002347] text-base">{medicine.tilte2}</p>
              <p className="text-[#002347] text-normal text-sm">
                {medicine.content}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AvialableProducts;
