import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PhoneIcon from "../../assets/images/svg/phoneicon1.svg";
import MailIcon from "../../assets/images/svg/MailIcon1.svg";
import LinkedIn from "../../assets/images/svg/linkedinIcon.svg";

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
  }, []);
  return (
    <>
      <div className="bg-[#002347] md:pt-20 pt-12">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 border-b border-b-white text-center">
          <a
            href="#"
            className="text-white text-4xl font-bold leading-none text-center"
          >
            Glee Biotech Limited
          </a>{" "}
          <p className="text-white opacity-85 max-w-[626px] mx-auto text-[16px] leading-[150%] text-center font-normal mt-5">
            Our aim is to be a trusted partner in the pharmaceutical industry by
            providing cost-effective manufacturing solutions. We are dedicated
            to improving patient health through high-quality products, strict
            regulatory compliance, and strong, lasting client partnerships.
          </p>
          <ul className="flex justify-center flex-wrap items-center sm:gap-20 gap-6 mt-8 md:mb-8 mb-5">
            <Link
              to="/"
              className="text-white text-base font-normal leading-[138%] after:w-0 after:h-[2px] after:bg-white after:absolute relative after:left-0 after:bottom-[-2px] hover:after:w-full after:duration-500 after:ease-in-out"
            >
              {" "}
              Home
            </Link>
            <Link
              to="/aboutus"
              className="text-white text-base font-normal leading-[138%] after:w-0 after:h-[2px] after:bg-white after:absolute relative after:left-0 after:bottom-[-2px] hover:after:w-full after:duration-500 after:ease-in-out"
            >
              {" "}
              About Us
            </Link>

            <Link
              to="/ourproducts"
              href=""
              className="text-white text-base font-normal leading-[138%] after:w-0 after:h-[2px] after:bg-white after:absolute relative after:left-0 after:bottom-[-2px] hover:after:w-full after:duration-500 after:ease-in-out"
            >
              {" "}
              Our Products
            </Link>

            <Link
              to="/contactus"
              className="text-white text-base font-normal leading-[138%] after:w-0 after:h-[2px] after:bg-white after:absolute relative after:left-0 after:bottom-[-2px] hover:after:w-full after:duration-500 after:ease-in-out"
            >
              {" "}
              Contact Us
            </Link>
          </ul>
          <div className="flex gap-10 justify-center items-center md:mb-8 mb-5">
            <a
              href="tel:+911244014675"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 text-[#002347] font-medium text-lg transition-all duration-300 ease-linear hover:-translate-y-2"
            >
              <img src={PhoneIcon} alt="PhoneIcon" width={26} height={26} />
            </a>
            <a
              href="mailto:info@gleebiotech.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 text-[#002347] font-medium text-lg transition-all duration-300 ease-linear hover:-translate-y-2"
            >
              <img
                src={MailIcon}
                alt="MailIcon"
                width={35}
                height={35}
                className="pt-1"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/glee-biotech-ltd/?trk=ppro_cprof&originalSubdomain=ae"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 text-[#002347] font-medium text-lg transition-all duration-300 ease-linear hover:-translate-y-2"
            >
              <img src={LinkedIn} alt="LinkedIn" width={25} height={25} />
            </a>
          </div>
        </div>
        <p className="fw-normal fs_8sm text-white opacity-85 text-center py-5">
          Copyright © <span id="year_change">{year} </span>
          Glee Biotech limited All rights reserved
        </p>
      </div>
    </>
  );
};

export default Footer;
