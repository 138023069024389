
import './App.css';
import React, { useState, useEffect } from "react";
import MainRoute from './components/common/MainRoute';
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from './components/common/Loader';
import { BackToTop } from './components/Icons';




function App() {
  // -----------------------aos------------------------
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

  }, []);
  // --------------------loader-----------------------------
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const scrollPosition = window.scrollY;

    const fetchData = async () => {
      try {
        setLoader(true);
        document.body.style.overflow = "hidden";
        await new Promise((resolve) => setTimeout(resolve, 1000));

        setLoader(false);
        document.body.style.overflow = "auto";
        window.scrollTo(0, scrollPosition);
      } catch (error) {
        console.error("Error fetching the data:", error);
        setLoader(false);
        document.body.style.overflow = "auto";
        window.scrollTo(0, scrollPosition);
      }
    };

    fetchData();

    return () => {
      document.body.style.overflow = "auto";
      window.scrollTo(0, scrollPosition);
    };
  }, []);
  // --------------------back-to-top-----------------------------
  const top = () => {
    document.documentElement.scrollTop = 0;
  };
  const [backToTop, setbackToTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 100) {
        setbackToTop(true);
      } else {
        setbackToTop(false);
      }
    });
  }, []);



  return <>
    {loader ? <Loader /> : <>
      <MainRoute />
      <button className={`${backToTop ? "fixed bottom-4 w-[35px] rounded-full h-[35px] right-4 animate-backtotop shadow-sm cursor-pointer flex justify-center items-center bg-[#efc368]" : "hidden"}`} onClick={() => top()}><BackToTop /></button>
    </>
    }</>;
}

export default App;

