import React from "react";
import { RightArrow1 } from "./Icons";
import Blog1 from "../assets/images/webp/blog1.webp";
import Blog2 from "../assets/images/webp/blog2.webp";
import Blog3 from "../assets/images/webp/blog3.webp";

const blogs = [
  {
    id: 1,
    img: Blog1,
    date: "June 2023",
    title: "5 pharmacist tips for a safe and healthy summer",
    description:
      "It’s time for some summer fun! Nothing beats pool parties, backyard barbecues, and trips to the beach. But be careful —",
    link: "https://www.express-scripts.com/pharmacy/blog/5-pharmacist-tips-for-summer",
  },
  {
    id: 2,
    img: Blog2,
    date: "Jan 2023",
    title: "6 questions Medicare members should ask their pharmacist",
    description:
      "As you get older, managing your health and medications can be more challenging than ever. You might be..",
    link: "https://www.express-scripts.com/pharmacy/blog/questions-medicare-members-should-ask",
  },
  {
    id: 3,
    img: Blog3,
    date: "May 2023",
    title: "How Pharmacists Can Assist With Drug Abuse",

    description:
      "Pharmacists play a critical role in reducing drug abuse. They are often the last line of defense between patients",
    link: "https://www.rxrelief.com/blog/how-pharmacists-can-assist-with-drug-abuse",
  },
];

const News = () => {
  return (
    <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
      <h3
        className="text-[#efc368] text-xl font-normal flex items-center"
        data-aos="fade-right"
      >
        <span className="max-w-28 w-full h-[1px] bg-[#002347] mr-2"></span>
        News in the Market
      </h3>
      <p
        className="text-3xl text-[#002347] font-bold mt-3"
        data-aos="fade-right"
      >
        Latest Health Blogs
      </p>
      <div
        className="flex flex-row flex-wrap mx-auto lg:justify-between justify-center mt-10 lg:gap-0 gap-10"
        data-aos="zoom-in"
      >
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="lg:w-[31%] sm:w-[40%] w-full flex-col items-stretch flex group hover:shadow-lg transition-all duration-300 ease-linear cursor-pointer"
          >
            <div className="overflow-hidden h-full">
              <img
                src={blog.img}
                alt={`Blog ${blog.id}`}
                className="w-full group-hover:scale-[1.03] transition-all duration-300 ease-linear"
              />
            </div>
            <div className="p-4 flex flex-col justify-between gap-4 h-full">
              <div className="flex justify-between items-center">
                <p className="text-[14px] text-[#002347] font-bold leading-[171.429%] tracking-[0.2px]">
                  By Admin
                </p>
                <p className="text-[12px] tracking-[0.2px] text-[#002347] transition-all duration-300 ease-linear group-hover:bg-[#efc368] p-[5px] rounded-[20px]">
                  {blog.date}
                </p>
              </div>
              <h3 className="text-[#002347] leading-5 text-[16px] font-bold tracking-[0.1px]">
                {blog.title}
              </h3>
              <p className="text-[#002347] text-[14px] font-normal leading-[142.857%] tracking-[0.2px]">
                {blog.description}
              </p>
              <a
                href={blog.link}
                target="_blank"
                className="flex items-center text-[#002347] w-full transition-all duration-300 ease-linear group-hover:text-[#efc368]"
              >
                Read More
                <span className="ml-2.5">
                  <RightArrow1 />
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
