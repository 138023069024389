import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import AvialableProducts from "../AvialableProducts";
import CatalogHeader from "../CatalogHeader";

const CatalogPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <div className="overflow-x-hidden">
        <CatalogHeader />
        <AvialableProducts />
        <Footer />
      </div>
    </>
  );
};

export default CatalogPage;
