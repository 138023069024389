import React from "react";
import { Link } from "react-router-dom";
import productData from "./common/Helper";
import { useNavigate } from "react-router-dom";
<productData />;
const OurProductHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="bg-contactbg bg-cover bg-no-repeat bg-center h-[300px] flex justify-center items-center"
        data-aos="fade-down"
      >
        <h2
          className="font-bold text-white sm:text-5xl text-4xl "
          data-aos="zoom-in"
        >
          Our Products
        </h2>
      </div>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:pb-20 pb-12">
        <div className="flex flex-wrap justify-between gap-2.5 h-full">
          {productData.map((product) => (
            <div
              className="md:w-[21%] sm:w-[40%] w-full text-center"
              data-aos="zoom-in"
              key={product.id}
            >
              <img
                src={product.image}
                alt={product.name}
                height="140"
                loading="lazy"
                className="Bn w-full md:px-0 px-10 aspect-square"
              />
              <h2 className="ObMbJTKT_d">{product.name}</h2>
              <p className="text-xs font-normal text-ellipsis mb-2">
                {product.description}
              </p>
              <Link
                to={`/productdetails/${product.id}`}
                className="btn inline-block font-medium text-nowrap text-sm px-6 py-1.5 text-[#002347] hover:text-[#efc368] bg-[#efc368] hover:bg-[#002347] shadow-[2px_3px_6px_#002347] rounded cursor-pointer transition duration-500"
              >
                Details
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OurProductHero;
