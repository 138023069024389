import Medicine from "../../assets/images/webp/drevino.webp";
import Medicine2 from "../../assets/images/webp/domcal.webp";
import Medicine3 from "../../assets/images/webp/glee-500.webp";
import Medicine4 from "../../assets/images/webp/thiatec-100.webp";
import Medicine5 from "../../assets/images/webp/gleedon_200.webp";
import Medicine6 from "../../assets/images/webp/aziglee_500.webp";
import Medicine7 from "../../assets/images/webp/gleeset.webp";
import Medicine8 from "../../assets/images/webp/gleehep.webp";
import Medicine9 from "../../assets/images/webp/ratro.webp";
import Medicine10 from "../../assets/images/webp/radrene.webp";
import Medicine11 from "../../assets/images/webp/scorbic.webp";
import Medicine12 from "../../assets/images/webp/neuron.webp";
import Medicine13 from "../../assets/images/webp/thione_600.webp";
import Medicine14 from "../../assets/images/webp/vitmineC.webp";
import Medicine15 from "../../assets/images/webp/gavnco_1000.webp";
import Medicine16 from "../../assets/images/webp/grifax_400.webp";
import Medicine17 from "../../assets/images/webp/duofill.webp";
import Medicine18 from "../../assets/images/webp/c_zinc.webp";
import Medicine19 from "../../assets/images/webp/gcaspo_50.webp";
import Medicine20 from "../../assets/images/webp/pirfedon_200.webp";

const productData = [
  {
    id: 1,
    name: "GL – Spas",
    description: "Drotaverine Hydrochloride Injection",
    image: Medicine,
    intro:
      "Drotaverine Injection is an antispasmodic medicine. It is used in the treatment of menstrual pain and abdominal pain. It provides relief from pain due to smooth muscle spasm like menstrual pain, pain due to kidney stones, pain due to biliary stone and gastrointestinal colicky pain. Before taking this medicine, tell your doctor if you have ever had severe heart disease or have blood pressure issues, or you have liver or kidney disease.",
    heading: "BENEFITS OF DROTIKIND INJECTION:",
    effect:
      "Drotikind Injection effectively relieves sudden muscle spasms or contractions in the stomach and intestine (gut), thereby improving the pain. This helps to treat abdominal pain (or stomach pain) as well as cramps, bloating and discomfort associated with menstrual cycles or kidney stones also.",
  },
  {
    id: 2,
    name: "Domcal Softgel Capsules",
    description: "Calcium Glycerophosphate Soft Gelatin Capsules",
    image: Medicine2,
    intro:
      "Calcium Glycerophosphate is a mineral supplement that plays a crucial role in maintaining the health of bones and teeth. It provides calcium, an essential element for the body, which is vital for various physiological functions, particularly for strong bones and teeth, muscle function, nerve transmission, and blood clotting.",
  },
  {
    id: 3,
    name: "Gleecef-500 Tablets",
    description: "Cerufoxine Axetil Tablets IP",
    image: Medicine3,
    intro:
      "Cefuroxime is used to treat certain infections caused by bacteria, such as bronchitis(infection of the airway tubes leading to the lungs); gonorrhea (a sexually transmitted disease), Lyme disease (an infection that may develop after a person is bitten by a tick); and infections of the skin, ears, sinuses, throat. It works by stopping the growth of bacteria.This antibiotic treats only bacterial infections. It will not work for viral infections (such as common cold, flu). Using any antibiotic when it is not needed can cause it to not work for future infections.",
    heading: "BENEFITS OF DROTIKIND INJECTION:",
    effect:
      "Tablet is a versatile antibiotic medicine that kills the infection-causing bacteria in your body. This medicine is used to treat many different types of infections such as those of the lungs (pneumonia) Stopping it early may make the infection come back and harder to treat.",
  },
  {
    id: 4,
    name: "Thiatec- 100",
    description: "Thiamine Hydrochloride Injection IP",
    image: Medicine4,
    intro:
      "Thiamine is a type of Vitamin B, also known as Vitamin B1. It helps the body to use carbohydrates for energy and is mostly found in heart, muscles and nervous system. Signs and symptoms for thiamine deficiency includes loss of appetite, fatigue, tingling sensation in arms and legs or muscle weakness. This medication is used to treat or prevent a lack of thiamine. Most people are able to obtain thiamine from their diet but certain medical or health conditions (eg poor nutrition may lead to beri-beri, prolonged vomiting, alcoholism, stomach/intestinal diseases, etc) can cause a deficiency in thiamine.",
    heading: "SIDE EFFECTS : ",
    effect:
      "Pain, redness or soreness at injection site, Contact dermatitis, Allergic reactions such as rash, itching/swelling (especially of face, tongue, throat), trouble breathing",
  },
  {
    id: 5,
    name: "Gleedon-200",
    description: "Pirfenidone Tablets IP 200mg",
    image: Medicine5,
    intro:
      "Pirfenidone is an anti-fibrotic and anti-inflammatory medication designed to slow down the progression of scarring (fibrosis) in the lungs. By reducing the production of certain growth factors and pro-fibrotic agents, Pirfenidone helps preserve lung function and improve quality of life for patients with IPF.",
  },
  {
    id: 6,
    name: "Aziglee-500 LB",
    description: "Azithromycin & Lactic Acid Bacillus Tablets",
    image: Medicine6,
    intro:
      "Azithromycin + Lactic acid bacillus is a combination of two medicines. Azithromycin is an antibiotic. It works by preventing synthesis of essential proteins required by bacteria to carry out vital functions. Thus, it stops the bacteria from growing, and prevents the infection from spreading. Lactic acid bacillus are inactivated bacteria that are good for the gut. They prevent the growth of the other pathogenic bacteria that cause diarrhea or other bacterial infections. Thus, it helps in treating bacterial infections.",
  },
  {
    id: 7,
    name: "Gleeset",
    description: "Ondansetron Injection IP",
    image: Medicine7,
    intro:
      "Ondansetron comes as a solution (liquid) to be injected intravenously (into a vein) or intramuscularly (into a muscle) by a health care provider in a hospital or clinic. When ondansetron is used to prevent nausea and vomiting caused by chemotherapy, it is usually given 30 minutes before the start of chemotherapy. Additional doses may be given 4 hours after the first dose of ondansetron and 8 hours after the first dose of ondansetron, if needed. When ondansetron is used to prevent nausea and vomiting caused by surgery, it is usually given just before the surgery. Ondansetron is also sometimes given after surgery to patients who are experiencing nausea and vomiting and who did not receive ondansetron before surgery.",
    heading: "SIDE EFFECTS : ",
    effect: "Headache, Constipation, Feeling cold or chills",
  },
  {
    id: 8,
    name: "....",
    description: "L-ornithine L-Aspartate Infusion ",
    image: Medicine8,
    intro:
      "L ORNITHINE L ASPARTATE is a combination medicine used to treat liver diseases such as acute and chronic hepatitis, chronic alcoholic liver damage, fatty liver, jaundice, liver cirrhosis, and non-alcoholic steatohepatitis (non-alcoholic fatty liver disease). Liver diseases lead to disturbance of vital liver functions. L ORNITHINE L ASPARTATE effectively prevents multiple liver disease mechanisms and ensures protection, regeneration, and rejuvenation of the liver.",
    heading: "SIDE EFFECTS : ",
    effect: "Headache, Fatigue, Nausea, Diarrhoea",
  },
  {
    id: 9,
    name: "Ratro",
    description: "Antropine Injection I.P.",
    image: Medicine9,
    intro:
      "This medicine is used to reduce saliva and fluid in the respiratory tract during surgery. It is also used to treat insecticide or mushroom poisoning. It can be used in an emergency to treat a slow heartbeat. This medicine may be used for other purposes; ask your health care provider or pharmacist if you have questions.",
    heading: "SIDE EFFECTS : ",
    effect:
      "Anxiety, Nervousness, Changes in vision, Fast or slow heartbeat, Feeling faint or lightheaded, Falls",
  },
  {
    id: 10,
    name: "Radrene",
    description: "Andrenaline Bitartrate Injection I.P",
    image: Medicine10,
    intro:
      "Regain Adrenaline Bitartrate Injection 1ml belongs to a class of medicines called 'sympathomimetic', primarily used for the treatment of cardiac arrest, hypotension due to septic shock and life-threatening allergic reactions. Cardiac arrest is a sudden loss of blood flow resulting from the failure of the heart to pump effectively. Severe allergic reactions, including anaphylaxis due to foods, drugs, insect stings, bites, or other allergens. It may cause symptoms like breathing difficulty, facial swelling, dizziness, confusion and hives.",
    heading: "SIDE EFFECTS : ",
    effect:
      "Increased heart rate, Difficulty in breathing, Mood or mental changes, Increase in blood pressure",
  },
  {
    id: 11,
    name: "Scorbic",
    description: "Ascorbic Acid Injection I.P.",
    image: Medicine11,
    intro:
      "If you have an allergy to ascorbic acid or any other part of ascorbic acid (vitamin C) injection. If you are allergic to ascorbic acid (vitamin C) injection; any part of ascorbic acid (vitamin C) injection; or any other drugs, foods, or substances. Tell your doctor about the allergy and what signs you had. This medicine may interact with other drugs or health problems. Tell your doctor and pharmacist about all of your drugs (prescription or OTC, natural products, vitamins) and health problems. You must check to make sure that it is safe for you to take ascorbic acid (vitamin C) injection with all of your drugs and health problems. Do not start, stop, or change the dose of any drug without checking with your doctor.",
    heading: "SIDE EFFECTS : ",
    effect: "Irritation where the shot is given",
  },
  {
    id: 12,
    name: "......",
    description: "Cerebroprotein Hydrolysate for Injection 60mg",
    image: Medicine12,
    intro:
      "Cerebroprotein Hydrolysate is used in the treatment of stroke, head injury and Alzheimer's disease. Cerebroprotein Hydrolysate is a nerve repairing medicine. It works on the brain by repairing the nerve cells and improves their survival.",
    heading: "SIDE EFFECTS : ",
    effect: "Nausea, Difficulty in breathing, Dizziness, Headache, Sweating",
  },
  {
    id: 13,
    name: "GL-Thione 600",
    description: "Glutathione for Injection 600 mg",
    image: Medicine13,
    intro:
      "Glutathione 600mg Injection is a medicine used in the treatment of liver disease. It also has a number of other health benefits and protects the body cells against damage caused by free radicals. It is administered as an injection by a healthcare professional. You should not self-administer this medicine at home. If you are an asthmatic patient, you must consult the doctor before starting the treatment. Pregnant and breastfeeding women must take this medicine only with a valid prescription.",
    heading: "SIDE EFFECTS : ",
    effect: "Injection site reactions (pain, swelling, redness)",
  },
  {
    id: 14,
    name: "Vitamic C",
    description: "Sterile water for Injection IP",
    image: Medicine14,
    intro:
      "Sterile Water for Injection, USP is a sterile, nonpyrogenic preparation of water for injection which contains no bacteriostat, antimicrobial agent or added buffer and is supplied only in single dose containers to dilute or dissolve drugs for injection. For IV injection, add sufficient amount to a solute to make an approximately isotonic solution. pH 5.0 to 7.0.",
    heading: "SIDE EFFECTS : ",
    effect:
      "Sterile Water for Injection must be made approximately isotonic prior to use.",
  },
  {
    id: 15,
    name: "G-Vanco 1000",
    description: "Vancomycin Hydrochloride for Injection U.S.P",
    image: Medicine15,
    intro:
      "Vancomycin injection is used alone or in combination with other medications to treat certain serious infections such as endocarditis (infection of the heart lining and valves), peritonitis (inflammation of the lining of the abdomen), and infections of the lungs, skin, blood, and bones.",
  },
  {
    id: 16,
    name: "Grifax- 400",
    description: "Rifaximin Tablets 400mg",
    image: Medicine16,
    intro:
      "Rifaximin is used to treat traveler's diarrhea that is caused by a bacteria called Escherichia coli. It is also used to prevent hepatic encephalopathy, which is a condition that occurs when your liver does not work normally. This medicine is also used to treat irritable bowel syndrome with diarrhea.",
  },
  {
    id: 17,
    name: "DuoFill Respules",
    description: "Levosalbutamol and Ipratropium Bromide Respirator Solution",
    image: Medicine17,
    intro:
      "Levosalbutamol + Ipratropium is a combination of two medicines: Levosalbutamol and Ipratropium. Levosalbutamol is a bronchodilator while Ipratropium is an anticholinergic. They work by relaxing the muscles in the airways and widen the airways. Together, they make breathing easier.",
  },
  {
    id: 18,
    name: "C-Zinc",
    description: "Multivitamin, Multimineral with Grape Seed Extract tablets",
    image: Medicine18,
    intro:
      "This supplement combines the benefits of multiple vitamins, minerals, and the powerful antioxidant properties of grape seed extract. It is commonly used to support general health, improve energy levels, and enhance the body's ability to fight free radicals, which can cause cell damage and contribute to aging and various diseases.",
  },
  {
    id: 19,
    name: "Gcaspo-50",
    description: "Caspofungin Acetate for Injection 50 mg",
    image: Medicine19,
    intro:
      "Caspofungin injection is used in adults and children 3 months of age and older to treat yeast infections in the blood, stomach, lungs, and esophagus (tube that connects the throat to the stomach.) and certain fungal infections that could not be treated successfully with other medications. It is also used to treat serious fungal infections in people with a weakened ability to fight infection. Caspofungin injection is in a class of antifungal medications called echinocandins. It works by slowing the growth of fungi that cause infection.",
  },
  {
    id: 20,
    name: "Pirfedon-200",
    description: "Pirfenidone Tablets IP 200mg",
    image: Medicine20,
    intro:
      "Pirfenidone is used to treat a certain lung disease called idiopathic pulmonary fibrosis (IPF). This disease causes the lungs to get scarred and become stiff, making it hard to breathe. Pirfenidone may help slow down the worsening of your IPF. Before taking pirfenidone tell your doctor or pharmacist if you are allergic to it; or if you have any other allergies. This product may contain inactive ingredients, which can cause allergic reactions or other problems. Talk to your pharmacist for more details.",
  },
];

export default productData;

