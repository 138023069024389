import React from "react";
import { Link } from "react-router-dom";

const AboutDesign = () => {
  return (
    <>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
        <div className="flex flex-wrap justify-between items-center lg:gap-0 gap-5">
          <div className="lg:w-[59%] w-full group" data-aos="fade-right">
            <div className="bg-grid1 bg-cover lg:py-40 py-20 px-5 group-hover:scale-[0.98] transition-all duration-300 ease-linear cursor-pointer">
              <p className="mb-4 text-white [text-shadow:1px_2px_3px_#000000] font-bold md:text-4xl text-2xl leading-2">
                Competitive Cost for your preferred medicine
              </p>
              <Link
                to="/contactus"
                className="py-1.5 text-lg bg-transparent border-2 group:hover group-hover:text-black transition-all duration-300 ease-linear group-hover:bg-white px-6 border-solid border-white text-white"
              >
                Contact Now
              </Link>
            </div>
          </div>
          <div className="lg:w-[40%] flex flex-col gap-4 w-full">
            <div className="group" data-aos="fade-down">
              <div className="bg-grid2 bg-cover lg:py-11 py-20 px-5 group-hover:scale-[0.98] transition-all duration-300 ease-linear cursor-pointer">
                <p className="mb-4 text-white [text-shadow:1px_2px_3px_#000000] font-bold md:text-4xl text-2xl leading-2">
                  Well Tested and hygienically proven!
                </p>
                <Link
                  to="/"
                  className="py-1.5 text-lg bg-transparent border-2 group:hover group-hover:text-black transition-all duration-300 ease-linear group-hover:bg-white px-6 border-solid border-white text-white"
                >
                  Shop Now
                </Link>
              </div>
            </div>
            <div className="group" data-aos="fade-up">
              <div className="bg-grid3 bg-cover lg:py-11 py-20  px-5 group-hover:scale-[0.98] transition-all duration-300 ease-linear cursor-pointer">
                <p className="mb-4 text-white [text-shadow:1px_2px_3px_#000000] font-bold md:text-4xl text-2xl leading-2">
                  Easily Available at your nearest store
                </p>
                <Link
                  to="/contactus"
                  className="py-1.5 text-lg bg-transparent border-2 group:hover group-hover:text-black transition-all duration-300 ease-linear group-hover:bg-white px-6 border-solid border-white text-white"
                >
                  Get Info
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDesign;
