import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../common/NavBar";
import HeroSection from "../HeroSection";
import PopularMedicines from "../PopularMedicines";
import Details from "../Details";
import AvialableProducts from "../AvialableProducts";
import Footer from "../common/Footer";
import OurBrands from "../OurBrands";
import News from "../News";
import GetInTouch from "../GetInTouch";

const HomePage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <HeroSection />
      <Details />
      <PopularMedicines />
      <AvialableProducts />
      <OurBrands />
      <News />
      <GetInTouch />
      <Footer />
    </>
  );
};

export default HomePage;
