import React from "react";
import Slider from "react-slick";
import Client1 from "../assets/images/webp/glee.webp";
import Client2 from "../assets/images/webp/doufill1.webp";
import Client3 from "../assets/images/webp/gvanco.webp";
import Client4 from "../assets/images/webp/czinc.webp";
import Client5 from "../assets/images/webp/rezole.webp";
import Client6 from "../assets/images/webp/aimbact.webp";
import Client7 from "../assets/images/webp/thiatec.webp";

const OurBrands = () => {
  const logo = [
    {
      image: Client7,
    },
    {
      image: Client2,
    },
    {
      image: Client3,
    },
    {
      image: Client4,
    },
    {
      image: Client5,
    },
    {
      image: Client6,
    },
    {
      image: Client1,
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 6000,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <div className="bg-slate-100">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
          <h3
            className="text-[#efc368] text-xl font-normal flex items-center"
            data-aos="fade-right"
          >
            <span className="max-w-28 w-full h-[1px] bg-[#002347] mr-2"></span>
            Our Brands
          </h3>
          <p
            className="text-3xl text-[#002347] font-bold mt-3"
            data-aos="fade-right"
          >
            We have a Wide Range
          </p>
          <div data-aos="fade-down" className="mt-10">
            <Slider {...settings}>
              {logo.map((a, index) => (
                <div key={index} className="flex items-center">
                  <img
                    src={a.image}
                    alt="cardimg"
                    width={120}
                    height={70}
                    className="object-fit-contain"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurBrands;
