import React from "react";
import Vision from "../assets/images/webp/vision.webp";
import Mision from "../assets/images/webp/missions.webp";

const OurVision = () => {
  return (
    <>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
        <div className="flex flex-wrap justify-between items-center lg:gap-0 gap-6">
          <div className="lg:w-[50%]">
            <h3
              className="text-4xl text-[#002347] font-bold mb-3"
              data-aos="fade-right"
            >
              Our Vision
            </h3>
            <p className="text-[#5a656b] text-lg" data-aos="fade-right">
              Glee Biotech Ltd. (GLEE) is a leading pharmaceutical manufacturer,
              established in 2020 and based in Gurugram, Haryana, with a 25,000
              sq. ft. warehousing facility. We specialize in developing,
              manufacturing, and packaging high-quality pharmaceuticals,
              injectables, and surgical solutions. Our focus on stringent
              quality standards, regulatory compliance, and innovation ensures
              that our products meet the highest industry standards, delivering
              safe and effective healthcare solutions.
            </p>
          </div>
          <div className="lg:w-[45%] w-full">
            {" "}
            <div className="group overflow-hidden rounded-3xl cursor-pointer">
              <img
                src={Vision}
                alt="Vision"
                className="group-hover:scale-105 transition-all duration-300 ease-linear w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap-reverse justify-between items-center mt-12 lg:gap-0 gap-6">
          <div className="lg:w-[45%] w-full">
            <div className="group overflow-hidden rounded-3xl cursor-pointer">
              <img
                src={Mision}
                alt="Mision"
                className="group-hover:scale-105 transition-all duration-300 ease-linear w-full"
              />
            </div>
          </div>
          <div className="lg:w-[50%]">
            <h3
              className="text-4xl text-[#002347] font-bold mb-3"
              data-aos="fade-left"
            >
              Our Mission
            </h3>
            <p className="text-[#5a656b] text-lg" data-aos="fade-left">
              Glee Biotech Ltd., established in 2020, is a rapidly growing
              pharmaceutical company specializing in high-quality
              pharmaceuticals, injectables, and surgical solutions. We are
              committed to quality and compliance, serving leading hospitals and
              achieving nearly INR 25 Crore in annual turnover within three
              years. Our dedicated team focuses on sustainability and ongoing
              research and development to meet healthcare needs, ensuring
              innovative solutions for better patient care.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurVision;
