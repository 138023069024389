import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import ProductDetailsHeader from "../ProductDetailsHeader";
const ProductDetails = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <div className="overflow-x-hidden">
        <ProductDetailsHeader />
      </div>
      <Footer />
    </>
  );
};

export default ProductDetails;
