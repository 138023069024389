export const PrevArrow = () => {
  return (
    <svg
      width="21"
      height="8"
      viewBox="0 0 21 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM21 4.5H1V3.5H21V4.5Z"
        fill="black"
      />
    </svg>
  );
};
export const NextArrow = () => {
  return (
    <svg
      width="21"
      height="8"
      viewBox="0 0 21 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
        fill="black"
      />
    </svg>
  );
};
export const RightArrow1 = () => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1015_649)">
        <path
          className="group-hover:fill-[#efc368] group-hover:-translate-x-1 transition-all duration-300 ease-linear"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.680771 0.680771C0.737928 0.623469 0.805828 0.578007 0.880583 0.546987C0.955337 0.515967 1.03548 0.5 1.11641 0.5C1.19735 0.5 1.27749 0.515967 1.35224 0.546987C1.427 0.578007 1.4949 0.623469 1.55205 0.680771L8.9358 8.06452C8.9931 8.12168 9.03857 8.18958 9.06959 8.26433C9.10061 8.33909 9.11657 8.41923 9.11657 8.50016C9.11657 8.5811 9.10061 8.66123 9.06959 8.73599C9.03857 8.81074 8.9931 8.87864 8.9358 8.9358L1.55205 16.3196C1.43651 16.4351 1.27981 16.5 1.11641 16.5C0.953015 16.5 0.79631 16.4351 0.680771 16.3196C0.565232 16.204 0.500322 16.0473 0.500322 15.8839C0.500322 15.7205 0.565232 15.5638 0.680771 15.4483L7.63011 8.50016L0.680771 1.55205C0.623469 1.4949 0.578006 1.427 0.546986 1.35224C0.515967 1.27749 0.5 1.19735 0.5 1.11641C0.5 1.03548 0.515967 0.955338 0.546986 0.880583C0.578006 0.805829 0.623469 0.737928 0.680771 0.680771Z"
          fill="#002347"
        />
      </g>
      <defs>
        <clipPath id="clip0_1015_649">
          <rect
            width="9"
            height="16"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const BackToTop = () => {
  return (
    <svg width="16" height="16" fill="#ffff" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
      ></path>
      <path
        fillRule="evenodd"
        d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
      ></path>
    </svg>
  );
};
