import React from "react";
import Slider from "react-slick";
import CommonBtn from "./common/CommonBtn";

const HeroSection = () => {
  const slider = React.useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const slides = [
    {
      id: 1,
      backgroundClass: "bg-heropattern1",
      title: "Glee Biotech Limited",
      description:
        "To deliver best and fast pharmaceutical products Glee Biotech Limited has enough space to conduct their quality research to serve top-notch medicines in the market.",
      buttonText: "About Us",
      path: "/aboutus",
    },
    {
      id: 2,
      backgroundClass: "bg-heropattern2",
      title: "Cost Effective Solution",
      description:
        "We Develop, manufacture and package a wide variety of pharmaceutical, injectables and surgical solutions with the competitive price across India.",

      buttonText: "Our Products",
      path: "/ourproducts",
    },
    {
      id: 3,
      backgroundClass: "bg-heropattern3",
      title: "Quality Assurance",
      description:
        "We make sure about the quality and the right product for the right need, and to keep improving our services and product through consistent efforts while delivered the best to our clients",
      buttonText: "Get in Touch",
      path: "/contactus",
    },
  ];

  return (
    <>
      <Slider ref={slider} {...settings}>
        {slides.map((slide) => (
          <div
            key={slide.id}
            className={`relative h-[85vh] ${slide.backgroundClass} bg-no-repeat lg:bg-bgfull bg-cover`}
          >
            <div className="absolute bg-slate-300 bg-opacity-70 backdrop-blur-[2px] z-0 left-0 top-0 end-0 bottom-0"></div>
            <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 relative z-10">
              <div className="lg:w-[48%] flex flex-col gap-4 absolute top-1/2 translate-y-full">
                <h1
                  className="font-bold text-[#002347] sm:text-5xl text-4xl [text-shadow:1px_2px_3px_#ffff]"
                  data-aos="fade-right"
                >
                  {slide.title}
                </h1>
                <p
                  className="font-medium text-[#002347] text-xl [text-shadow:1px_2px_3px_#ffff]"
                  data-aos="fade-right"
                >
                  {slide.description}
                </p>
                <div data-aos="fade-right">
                  <CommonBtn btnname={slide.buttonText} path={slide.path} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default HeroSection;
