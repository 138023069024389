import React from "react";
import HomeIcon from "../assets/images/svg/HomeIcon.svg";
import MailIcon from "../assets/images/svg/MailIcon.svg";
import PhoneIcon from "../assets/images/svg/phoneicon.svg";

const LocationByMap = () => {
  return (
    <>
      <div className="md:pb-20 pb-12 pt-0">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4">
          <div className="flex flex-wrap items-center justify-between lg:gap-0 gap-7">
            <div className="lg:w-[40%]">
              <ul className="flex flex-col gap-4 mb-0 ps-0">
                <li className="flex gap-1 items-start">
                  <img src={HomeIcon} alt="HomeIcon" width={30} height={30} />
                  <div className="mt-1">
                    <p className="text-[#002347] font-medium text-xl">
                      Registered Office
                    </p>
                    <p className="text-lg font-medium opacity-75 text-[#002347]">
                      Glee Biotech Limited
                    </p>
                    <p className="text-black opacity-70">
                      301-302, Block & Pocket B-4, Sector-8, Rohini, New Delhi,
                      Delhi - 110085, India.
                    </p>
                    <p className="text-[#002347] opacity-75">
                      <span className="font-medium ">CIN : </span>
                      U33100DL2020PLC362536
                    </p>
                  </div>
                </li>
                <li className="flex gap-1 items-start">
                  <img src={HomeIcon} alt="HomeIcon" width={30} height={30} />
                  <div className="mt-1">
                    <p className="text-[#002347] font-medium text-xl">
                      Warehouse
                    </p>
                    <p className="text-black opacity-70">
                      Plot no.581, Basement Pace City 2, Sector 37, Industrial
                      Area, Gurugram 122004
                    </p>
                  </div>
                </li>
                <li className="flex gap-1 items-start">
                  <a
                    href="mailto:info@gleebiotech.com"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-2 text-[#002347] font-medium text-lg"
                  >
                    <img src={MailIcon} alt="MailIcon" width={25} height={25} />
                    info@gleebiotech.com
                  </a>
                </li>
                <li className="flex gap-1 items-start">
                  <a
                    href="tel:+911244014675"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-2 text-[#002347] font-medium text-lg"
                  >
                    <img
                      src={PhoneIcon}
                      alt="PhoneIcon"
                      width={25}
                      height={25}
                    />
                    +911244014675
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2 w-full">
              <div data-aos="fade-down">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4961.434870431194!2d77.03534980180912!3d28.441449846327092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b6ea67792d%3A0xe28bd8ef251ecf0f!2sGlee%20Biotech%20LTD!5e0!3m2!1sen!2sin!4v1728017477577!5m2!1sen!2sin"
                  width="100%"
                  height="340px"
                  loading="lazy"
                  className="w-full"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationByMap;
