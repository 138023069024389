import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CommonBtn from "./common/CommonBtn";
import Menwithlaptop from "../assets/images/svg/contact_img.svg";

const ContactHeader = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    name: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await fetch(
        "http://aditya.monurana.xyz:3000/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      resetForm();
      alert("Our team will contact you soon...");
    } catch (error) {
      console.error("Error submitting form", error);
      alert("An error occurred while submitting the form. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div
        className="bg-contactbg bg-cover bg-no-repeat bg-center h-[300px] flex justify-center items-center"
        data-aos="fade-down"
      >
        <h2
          className="font-bold text-white sm:text-5xl text-4xl"
          data-aos="zoom-in"
        >
          Contact Us
        </h2>
      </div>
      <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 pt-12 pb-1">
        <div className="flex flex-wrap justify-between items-center">
          <div className="xl:w-[42%] lg:w-[48%] w-full" data-aos="fade-right">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-4">
                  <div className="md:flex items-center justify-between gap-3">
                    <div className="w-full">
                      <label
                        className="font-normal text-[#131200] text-[16px] opacity-[70%]"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <Field
                        className="outline-none border-solid border-[1px] border-[#13120033] p-2 w-full rounded-[5px]"
                        type="text"
                        id="name"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div className="w-full">
                      <label
                        className="font-normal text-[#131200] text-[16px] opacity-[70%]"
                        htmlFor="lastname"
                      >
                        Last Name
                      </label>
                      <Field
                        className="outline-none border-solid border-[1px] border-[#13120033] p-2 w-full rounded-[5px]"
                        type="text"
                        id="lastname"
                        name="lastname"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="font-normal text-[#131200] text-[16px] opacity-[70%]"
                      htmlFor="mobile"
                    >
                      Phone Number
                    </label>
                    <Field
                      className="outline-none border-solid border-[1px] border-[#13120033] p-2 w-full rounded-[5px]"
                      type="text"
                      id="mobile"
                      name="mobile"
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      className="font-normal text-[#131200] text-[16px] opacity-[70%]"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      className="outline-none border-solid border-[1px] border-[#13120033] p-2 w-full rounded-[5px]"
                      type="email"
                      id="email"
                      name="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="form-group w-full">
                    <label
                      className="font-normal text-[#131200] text-[16px] opacity-[70%]"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <Field
                      className="min-h-[100px] outline-none border-solid border-[1px] border-[#13120033] w-full p-2 rounded-[5px] resize-none"
                      as="textarea"
                      id="message"
                      name="message"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <CommonBtn
                      className="submit-button"
                      btnname={isSubmitting ? "Submitting..." : "Submit"}
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="xl:w-[52.46%] lg:w-[50%] w-full">
            <img
              src={Menwithlaptop}
              alt="Men withlaptop"
              className="w-full rounded-xl animate-up-down"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHeader;
