import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../common/NavBar";
import OurProductHero from "../OurProductHero";
import Footer from "../common/Footer";

const OurProducts = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <OurProductHero />
      <Footer />
    </>
  );
};

export default OurProducts;
