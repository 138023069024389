import React from "react";
import Monitoring from "../assets/images/svg/moniter.svg";
import Support from "../assets/images/svg/supportchat.svg";
import Growth from "../assets/images/svg/growth.svg";

const HowWeWork = () => {
  return (
    <>
      <div className="bg-slate-100">
        <div className="xl:max-w-[1320px] mx-auto md:px-6 px-4 md:py-20 py-12">
          <h3
            className="text-[#efc368] text-xl font-normal flex items-center"
            data-aos="fade-right"
          >
            <span className="max-w-28 w-full h-[1px] bg-[#002347] mr-2"></span>
            How we Work
          </h3>
          <p
            className="text-3xl text-[#002347] font-bold mt-3"
            data-aos="fade-right"
          >
            Effective Medicine Delivery
          </p>
          <div className="flex flex-row flex-wrap mx-auto lg:justify-between justify-center gap-7 mt-8">
            <div className="lg:w-[30%] md:w-[48%] sm:w-full" data-aos="fade-up">
              <div className="lg:py-[35px] lg:px-[40px] p-5 gap-5 shadow-[0px_13px_19px_0px_rgba(0,_0,_0,_0.07)] flex flex-col justify-center items-center hover:shadow-[0px_20px_25px_3px_rgba(0,_0,_0,_0.07)] transition-all duration-300 ease-linear cursor-pointer">
                <img src={Support} alt="Support" className="w-[45px]" />
                <h2 className="text-[#252B42] text-[16px] font-bold leading-[150%] tracking-[0.1px]">
                  Support Chat
                </h2>

                <p className="text-[14px] text-[#737373] font-normal leading-[142.857%] tracking-[0.2px] text-center">
                  Our sales team is active and get in touch with our users to
                  quickly resolve their issues and give them the best possible
                  services.
                </p>
              </div>
            </div>
            <div
              className="lg:w-[30%] md:w-[48%] sm:w-full"
              data-aos="fade-down"
            >
              <div className="lg:py-[35px] lg:px-[40px] p-5 gap-5 shadow-[0px_13px_19px_0px_rgba(0,_0,_0,_0.07)] flex flex-col justify-center items-center hover:shadow-[0px_20px_25px_3px_rgba(0,_0,_0,_0.07)] transition-all duration-300 ease-linear cursor-pointer">
                <img src={Monitoring} alt="Monitoring" className="w-[45px] " />
                <h2 className="text-[#252B42] text-[16px] font-bold leading-[150%] tracking-[0.1px]">
                  Monitoring
                </h2>

                <p className="text-[14px] text-[#737373] font-normal leading-[142.857%] tracking-[0.2px] text-center">
                  We pay a very close attention to our monitoring system that we
                  make sure that even a single product meets it quality
                  standards
                </p>
              </div>
            </div>
            <div className="lg:w-[30%] md:w-[48%] sm:w-full" data-aos="fade-up">
              <div className="lg:py-[35px] lg:px-[40px] p-5 gap-5 shadow-[0px_13px_19px_0px_rgba(0,_0,_0,_0.07)] flex flex-col justify-center items-center hover:shadow-[0px_20px_25px_3px_rgba(0,_0,_0,_0.07)] transition-all duration-300 ease-linear cursor-pointer">
                <img src={Growth} alt="Growth" className="w-[45px]" />
                <h2 className="text-[#252B42] text-[16px] font-bold leading-[150%] tracking-[0.1px]">
                  Growth
                </h2>

                <p className="text-[14px] text-[#737373] font-normal leading-[142.857%] tracking-[0.2px] text-center">
                  We consistently work on the growing our efforts to make our
                  system and our products more value driven for our end users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWeWork;
